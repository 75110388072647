<template>
  <div>
    <v-autocomplete
      :multiple="multiple"
      :disabled="envId == null"
      outlined
      :items="contentTypes"
      item-text="name"
      item-value="sys.id"
      v-model="selection"
      :loading="loading"
    />
    <SnackBarQueue v-model="messages"></SnackBarQueue>
  </div>
</template>

<script>
import SnackBarQueue from "../../helpers/SnackBarQueue";

export default {
  data() {
    return {
      loading: false,
      messages: [],
      contentTypes: [],
      selection: null,
    };
  },
  props: ["spaceId", "envId", "multiple", "filterItems"],
  emits: ["changedSelection"],
  components: {
    SnackBarQueue,
  },
  watch: {
    envId() {
      if (this.spaceId && this.envId) {
        this.getContentModel();
      } else {
        this.contentTypes = [];
        this.selection = null;
      }
    },
    selection: {
      handler(newArray, oldArray) {
        this.$emit("changedSelection", this.selection);
      },
      deep: true, // Watch for deep changes
    },
  },
  methods: {
    async getContentModel() {
      this.loading = true;
      this.axios
        .get(`/contentful/contentModel?spaceId=${this.spaceId}&environmentId=${this.envId}`)
        .then((res) => {
          if (res && res.data && res.data.contentModel) {
            this.contentTypes = res.data.contentModel.items.sort((a, b) => (a.name > b.name) - (a.name < b.name));
            if (this.filterItems) this.contentTypes = this.contentTypes.filter(this.filterItems);
          }
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped></style>
