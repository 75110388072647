<template>
  <v-container class="main" fluid>
    <SnackBarQueue v-model="messages"></SnackBarQueue>

    <!-- Navigation Menu -->
    <v-tabs v-model="tab" color="#00838F" fixed-tabs center-active centered>
      <v-tab href="#overview" @click="changeTab('overview')">Overview</v-tab>
      <v-tab style="display:none" href="#visualize-model" @click="changeTab('visualize-model')">Visualize Model</v-tab>
      <v-tab href="#compare-models" @click="changeTab('compare-models')">Compare Models</v-tab>
      <v-tab href="#copy-model" @click="changeTab('copy-model')">Copy Model</v-tab>
      <v-tab href="#copy-entries" @click="changeTab('copy-entries')">Copy Entries</v-tab>
      <v-tab href="#copy-assets" @click="changeTab('copy-assets')">Copy Assets</v-tab>
      <v-tab href="#copy-user-roles" @click="changeTab('copy-user-roles')">Copy User Roles</v-tab>
      <v-tab href="#run-script" @click="changeTab('run-script')">Run Script</v-tab>
      <v-tab href="#release-version" @click="changeTab('release-version')">Release Version</v-tab>
      <v-tab href="#upgrade-version" @click="changeTab('upgrade-version')">Upgrade Version</v-tab>
      <v-tab href="#list-entries" @click="changeTab('list-entries')">List Entries</v-tab>
      <v-tab href="#edit-validations" @click="changeTab('edit-validations')">Edit Validations</v-tab>
    </v-tabs>

    <!-- Content -->
    <v-container class="main" fluid>
      <v-tabs-items v-model="tab">
        <!-- Overview -->
        <v-tab-item value="overview">
          <v-card outlined flat class="pa-10">
            <v-list nav dense rouned>
              <v-list-item v-for="(space, index) of spaces" :key="space.id">
                <v-list-item-content>
                  <v-list-item-title style="font-weight:bold">
                    <v-label @click="getSpaceDetails(space.id)">
                      <span style="cursor:pointer !important">{{ space.name }}</span>
                    </v-label>
                    <br />
                    <span style="" class="sub-title">{{ space.createdAt | formatDateTime }}</span>
                    <br v-if="index === spaces.length - 1" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>

        <!-- View Space -->
        <v-tab-item value="view-space">
          <v-card outlined flat class="pa-10">
            <v-row style="padding:10px;margin:10px;height:100%">
              <v-col>
                <h2>{{ selectedSpace.name }}</h2>
                <v-divider class="horizontal-divider" /><br />

                <!-- Environments -->
                <v-card outlined>
                  <v-row class="header-row">
                    <v-col>Environment ID</v-col>
                  </v-row>

                  <v-row class="" v-for="(environment, index) of selectedSpace.environments" :key="environment.id">
                    <v-row align="center">
                      <v-col style="padding: 50px">
                        <span style="padding-left:5px;margin-left:5px;font-weight:bold">
                          <v-label>{{ environment.name }}</v-label>
                        </span>
                      </v-col>
                      <v-col>
                        <v-row justify="end">
                          <v-btn class="primary-button" style="margin-right:10px" dark @click="getContentModel(environment.id)">
                            <v-icon dark>mdi-format-list-bulleted</v-icon>List Model
                          </v-btn>
                          <v-btn class="primary-button" dark @click="visualiseModel(environment.id)">
                            <v-icon dark>mdi-graph-outline</v-icon>Visual Model
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row :style="index === selectedSpace.environments.length - 1 ? 'margin-top: 20px' : ''">
                      <v-divider inset class="horizontal-divider-dense" v-if="index !== selectedSpace.environments.length - 1"></v-divider>
                    </v-row>
                  </v-row>
                </v-card>
                <br />

                <!-- Content Model List View -->
                <v-card outlined v-if="contentModel && contentModel.items && contentModel.items.length > 0">
                  <v-row class="header-row">
                    <v-col>Content Type</v-col>
                    <v-col>Fields</v-col>
                  </v-row>

                  <v-row class="table-row" v-for="(contentType, index) of contentModel.items" :key="contentType.sys.id">
                    <v-row style="padding: 15px">
                      <v-col>
                        <span style="padding-left:5px;margin-left:5px;font-weight:bold">
                          <v-label style="cursor:pointer !important">{{ contentType.name }}</v-label>
                        </span>
                      </v-col>
                      <v-col>
                        <span style="padding-left:-10px;margin-left:-10px">{{ contentType.fields.length }}</span>
                      </v-col>
                    </v-row>
                    <v-row :style="index === contentModel.items.length - 1 ? 'margin-top: 20px' : ''">
                      <v-divider class="horizontal-divider" v-if="index != contentModel.items.length - 1"></v-divider>
                    </v-row>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <!-- Content Model Visual View -->
        <v-tab-item value="view-model">
          <v-card outlined flat class="pa-10">
            <v-row style="padding:10px;margin:10px;height:100%">
              <v-col>
                <v-row align="center">
                  <v-col>
                    <v-row align="center">
                      <v-btn class="primary-button" dark @click="getSpaceDetails(selectedSpaceId)">&lt;</v-btn>
                      <h2>{{ selectedSpace.name }} - {{ selectedEnvironmentId }}</h2>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row align="center" justify="end"> </v-row>
                  </v-col>
                </v-row>
                <v-divider class="horizontal-divider" /><br />

                <!-- Content Model Visual -->
                <v-card flat style="width:100%">
                  <network id="visualModel" class="wrapper" ref="network" :nodes="nodes" :edges="edges" :options="options"> </network>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>

        <v-tab-item value="visualize-model">
          <VisualizeModel />
        </v-tab-item>

        <v-tab-item value="compare-models">
          <CompareModels />
        </v-tab-item>

        <v-tab-item value="copy-model">
          <CopyModel />
        </v-tab-item>

        <v-tab-item value="copy-entries">
          <CopyEntries />
        </v-tab-item>

        <v-tab-item value="copy-assets">
          <strong>TODO</strong>
        </v-tab-item>

        <v-tab-item value="copy-user-roles">
          <CopyUserRoles />
        </v-tab-item>

        <v-tab-item value="run-script">
          <RunScript />
        </v-tab-item>

        <v-tab-item value="release-version">
          <ReleaseVersion />
        </v-tab-item>

        <v-tab-item value="upgrade-version">
          <UpgradeVersion />
        </v-tab-item>

        <v-tab-item value="list-entries">
          <ListEntries />
        </v-tab-item>

        <v-tab-item value="edit-validations">
          <EditValidations />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-container>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import VisualizeModel from "./VisualizeModel";
import CompareModels from "./CompareModels";
import CopyModel from "./CopyModel";
import CopyEntries from "./CopyEntries";
import CopyUserRoles from "./CopyUserRoles";
import RunScript from "./RunScript";
import ReleaseVersion from "./ReleaseVersion";
import UpgradeVersion from "./UpgradeVersion";
import SnackBarQueue from "../helpers/SnackBarQueue";
import ListEntries from "./ListEntries";
import EditValidations from "./EditValidations.vue";
import { mapState } from "vuex";

export default {
  name: "Contentful",
  props: ["activeTab"],
  components: {
    VisualizeModel,
    CompareModels,
    CopyModel,
    CopyEntries,
    CopyUserRoles,
    RunScript,
    ReleaseVersion,
    UpgradeVersion,
    SnackBarQueue,
    ListEntries,
    EditValidations,
  },

  data() {
    return {
      isLoading: false,
      messages: [],

      tab: null,

      selectedSpaceId: "",
      selectedSpace: {},
      selectedEnvironmentId: "",
      contentModel: {},

      nodes: [],
      edges: [],
      options: {
        nodes: {
          borderWidth: 1,
          color: "#ffffff",
          shape: "box",
          margin: 10,
          font: { align: "left", multi: "html", size: 20 },
        },
        edges: {
          color: "#90A4AE",
          arrows: {
            to: {
              enabled: true,
              type: "arrow",
            },
          },
          smooth: false,
        },
        physics: {
          enabled: false,
        },
      },
    };
  },

  computed: {
    ...mapState({
      spaces: (state) => state.contentfulSpaces,
    }),
  },

  methods: {
    async getSpaceDetails(spaceId) {
      this.selectedSpaceId = spaceId;
      this.isLoading = true;

      this.axios
        .get(`/contentful/space/${this.selectedSpaceId}`)
        .then((res) => {
          this.selectedSpace = res.data.space;
          this.isLoading = false;

          this.tab = "view-space";
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error.message);
        });
    },

    async getContentModel(environmentId) {
      this.selectedEnvironmentId = environmentId;
      this.isLoading = true;

      this.axios
        .get(`/contentful/contentModel?spaceId=${this.selectedSpaceId}&environmentId=${environmentId}`)
        .then((res) => {
          this.contentModel = res.data.contentModel;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error.message);
        });
    },

    async visualiseModel(environmentId) {
      this.selectedEnvironmentId = environmentId;
      this.isLoading = true;

      this.axios
        .get(`/contentful/visualiseModel?spaceId=${this.selectedSpaceId}&environmentId=${environmentId}`)
        .then((res) => {
          this.nodes = res.data.contentModel.nodes;
          this.edges = res.data.contentModel.edges;

          this.tab = "view-model";
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error.message);
        });
    },

    changeTab(tabName) {
      if (tabName == undefined) {
        tabName = "overview";
      }
      this.tab = tabName;
      this.$router.replace("/contentful/" + tabName);
    },
  },

  mounted() {},
  created() {
    this.$store.dispatch("loadContentfulSpaces");
    this.changeTab(this.activeTab);
  },
  watch: {},
};
</script>

<style scoped>
.main {
  height: 98%;
  width: 98%;
  padding: 20px !important;
  margin: 20px !important;
}

div {
  width: 100%;
  text-align: left;
}

.row-detail {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.v-tabs {
  padding: 0px !important;
  margin: 0px !important;
}

.v-tab {
  padding: 10px !important;
  margin: 10px !important;
}

.header-row {
  background-color: #efefef;
  font-weight: normal;
  font-size: 14pt;

  min-height: 50px;
  margin-left: 0px;
  margin-right: 30px;
  padding-right: 30px;
  margin-top: 0px;
  padding-top: 0px;
}

.table-row {
  margin-left: 10px;
  margin-right: 22px;
  padding-right: 22px;
  margin-top: 10px;
  padding-top: 10px;
}

.table-row-dense {
  margin-left: 10px;
  margin-right: 22px;
  padding-right: 22px;
  margin-top: 0px;
  padding-top: 0px;
}

.wrapper {
  min-height: 100vh;
  height: 100vh;
  background-color: #ffffff;
  padding: 10px;
  width: 90%;
}
</style>
